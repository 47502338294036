import './DataViewer.css';

import { saveAs } from 'file-saver';

import { useParams } from "react-router-dom";
import {useEffect, useState} from "react";
import config from '../environment';
import SendMessageForm from './SendMessageForm';

const apiServerUrl = config.apiServerUrl;

const norskDate = (date) => {
    if (!date) {
        return null
    }
    return new Intl.DateTimeFormat('nb-NO', {
        day: '2-digit', month: '2-digit', year: 'numeric', literal: '/'
    }).format(new Date(date));
}

const MedicationView = (medication) => {
    if (!medication) return <div></div>
    return (
        <div key={medication.id} className="patient-data-row">
            <span className="patient-data-value">{medication.medication?.name}</span>
            <span className="patient-data-date">{medication.doctorDosage}</span>
        </div>
    )
};

const ConditionsView = (condition) => {
    if (!condition) return <div></div>
    return (
        <div key={condition.id} className="patient-data-row">
            <span className="patient-data-value">{condition.text} {condition.code}</span>
            <span className="patient-data-date">{norskDate(condition.diagnosed)}</span>
        </div>
    )
};

const AllergiesView = (allergy) => {
    if (!allergy) return <div></div>
    return (
        <div key={allergy.id} className="patient-data-row">
            <span className="patient-data-value">{allergy.text}</span>
            <span className="patient-data-date">{norskDate(allergy.recorded)}</span>
            
        </div>
    )
};

const NoteView = (note) => {
    if (!note) {
        return null
    }
    return (
        <div className="note-view">
            <div style={{display: 'none'}}>id: {note.noteId}</div>
            <div style={{display: 'none'}}>type: {note.type}</div>
            <div className='note-title'>{note.title}</div>
            <div  style={{display: 'none'}}>created: {note.created}</div>
            <div className='note-text'>{note.text}</div>
        </div>
    )
}

const fallbackCopyTextToClipboard = (text) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}

const copyTextToClipboard = (text) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
    }, function(err) {
        alert('Could not copy text, error: ' + err);
        console.error('Async: Could not copy text: ', err);
    });
}

const medicationSort = (a, b) => {
    if ((a.status === 'ACTIVE') && (b.status === 'ACTIVE')) {
        return 0;
    }
    if (a.status === 'ACTIVE') {
        return -1;
    }
    return 1;
}

const medicationFilter = (m) => {
    return m.status === 'ACTIVE';
}

const getMedicationSummaryText = (json) => {
    let summary = 'Medisiner:\n';
    if (json.medicationUsages) {
        summary += json.medicationUsages
            .sort(medicationSort)
            .filter(medicationFilter)
            .map(m => m.medication?.name + '\n' + m.doctorDosage + '\n'
        ).join('');
    }
    summary += '\n';
    return summary;
}

const getConditionsSummaryText = (json) => {
    let summary = 'Diagnoser:\n';
    if (json.conditions) {
        summary += json.conditions.map(c => {
            return c.diagnosed + ': ' + c.code + ' ' + c.text + '\n';
        }).join('');
    }
    summary += '\n';
    return summary;
}

const getAllergiesSummaryText = (json) => {
    let summary = 'Allergier:\n';
    if (json.allergiesIntolerances) {
        summary += json.allergiesIntolerances.map(a => {
            return a.recorded + ': ' + a.text + '\n';
        }).join('\n');
    }
    summary += '\n';
    return summary;
}

const copySummary = (json) => {
    const summary = getAllergiesSummaryText(json) +
                    getConditionsSummaryText(json) +
                    getMedicationSummaryText(json);

    copyTextToClipboard(summary);
}

const getSharedDataJson = async (serverUrl, shareId) => {
    return fetch(serverUrl + '/view/' + shareId, {
        method: 'GET',
    })
        .then(response => {
            return response.json();
        })
        .catch(error => {
            console.error('ERROR: ', error);
        });
}

const downloadFile = async (serverUrl, shareId, fileId) => {
    return fetch(serverUrl + '/view/' + shareId + '/file/' + fileId, {
        method: 'GET',
    })
        .then(response => {
            return response.json();
        }).then(json => {
            console.log('json', json)
            //saveFile(json.name, json.data);
            const binaryData = atob(json.data);
            //Buffer.from(str, 'base64')

            const byteArray = new Uint8Array(binaryData.length);
            for (let i = 0; i < binaryData.length; i++) {
            byteArray[i] = binaryData.charCodeAt(i);
            }

            var blob = new Blob([byteArray]);
            saveAs(blob, json.name);
        })
        .catch(error => {
            console.error('ERROR: ', error);
        });
}

const DataViewer = () => {
    let params = useParams();
    const shareId = params.shareId;

    const [sharedDataJson, setSharedDataJson] = useState(null);
    useEffect(() => {
        const fetchJson = async () => {
            const json = await getSharedDataJson(apiServerUrl, shareId);
            setSharedDataJson(json);
        };
        fetchJson().catch(console.error);
    }, []);

    if (!sharedDataJson) {
        return <div>Lasting...</div>
    }

    const json = JSON.parse(sharedDataJson.data);

    return (
        <div className="data-viewer">
            <div className="data-viewer-patient">            
                <div className="data-viewer-profile">
                    <div>
                        <h2>HealthB pasient</h2>
                    </div>
                    <div style={{fontStyle: 'italic', marginBottom: '10px', fontSize: 'larger'}}>Kontaktinformasjon</div>

                    <div className="person-details">
                        <div>
                            <span style={{display: 'inline-block', width: '100px'}}>Navn</span>
                            <span>{json.me?.name}</span>
                        </div>
                        <div>
                            <span style={{display: 'inline-block', width: '100px'}}>Født</span>
                            <span>{norskDate(json.me?.dateOfBirth)}</span>
                        </div>
                        <div style={{marginTop: '10px'}}>
                            <span style={{display: 'inline-block', width: '100px'}}>Telefon</span>
                            <span>{json.me?.phoneNumber}</span>
                        </div>
                        <div>
                            <span style={{display: 'inline-block', width: '100px'}}>E-post</span>
                            <span>{json.me?.email}</span>
                        </div>
                    </div>
                </div>

                <div style={{fontStyle: 'italic', marginBottom: '10px', marginTop: '20px', fontSize: 'larger'}}>Oversikt</div>

                <div className="patient-data-section">
                    <div className="patient-data-section-title">Allergier</div>
                    {json.allergiesIntolerances?.map(m => AllergiesView(m))}
                </div>
                <div className="patient-data-section">
                    <div className="patient-data-section-title">Diagnoser</div>
                    {json.conditions?.map(m => ConditionsView(m))}
                </div>
                <div className="patient-data-section">
                    <div className="patient-data-section-title">Medisiner</div>
                    {json.medicationUsages?.sort(medicationSort)
                        .filter(medicationFilter)
                        .map(m => MedicationView(m))}
                </div>
                <div>
                    <button className="healthb-primary-btn" style={{marginTop: '40px'}} onClick={()=>copySummary(json)}>
                        Kopier & lim inn i ditt pasientjournalsystem  
                    </button>
                </div>
            </div>
            <div className="data-view-messages">
                <div className="notes-view">
                    <div style={{marginBottom: '2rem'}}><h4>Notater fra pasient:</h4></div>
                    {json.notes && json.notes.map(note => NoteView(note))}
                </div>
                <div>
                    <div onClick={() => downloadFile(apiServerUrl, '9250f282-6b74-11ee-b962-0242ac120002', '95efefe1-d0f2-4820-bec4-ffd09ce79668')}> save file </div>
                </div>
                <SendMessageForm shareId={shareId} />
            </div>
        </div>
    )
};

export default DataViewer;
